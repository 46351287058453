var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "vueDkgisContainer",
      staticClass: "vue-dkgis-container",
      attrs: { id: "vueDkgisContainer" }
    },
    [
      _vm._l(_vm.eventMarkerData, function(marker) {
        return _c("dk-event-marker", {
          key: marker.seq,
          ref: "eventMarker",
          refInFor: true,
          attrs: {
            data: marker,
            mapObject: _vm.mapObject,
            geometry: marker.geometry,
            properties: marker.properties,
            dataObj: marker,
            markerInfoWindow: _vm.markerInfoWindow,
            isEventMarker: true
          },
          on: { click: _vm.clickEventMarker }
        })
      }),
      _vm._l(_vm.markerData, function(marker, index) {
        return _c("dk-marker", {
          key: marker.properties.fcltId || index,
          ref: "marker",
          refInFor: true,
          attrs: {
            data: marker,
            mapObject: _vm.mapObject,
            geometry: marker.geometry,
            properties: marker.properties,
            draggable: marker.draggable,
            dataObj: marker,
            markerInfoWindow: _vm.markerInfoWindow,
            status: marker.status
          },
          on: { click: _vm.clickMarker }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }