import dkMarker from '../dkGisMarker';
import Feature from 'ol/Feature.js';
import { Style } from 'ol/style.js';
import { Icon as IconSource, Text, Fill, Stroke } from 'ol/style';
import Point from 'ol/geom/Point';
import { Translate } from 'ol/interaction';
import { Collection } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Select from 'ol/interaction/Select';
import Overlay from 'ol/Overlay.js';

export default {
  mixins: [dkMarker],
  template: `<div>
    <div id="popup" title="Welcome to OpenLayers"></div>
  </div>`,
  data() {
    return {
      olPoint: null,
    };
  },
  created() {
    let me = this;
    this.gisMarkerData = this.$dkPlugin.getGisMarker(this.properties.fcltTypeId);
    if (this.gisMarkerData) {
      new Promise((resolve, reject) => {
        var marker = new Image();
        marker.crossOrigin = 'Anonymous';
        marker.src = this.gisMarkerData.gisMarkerImage.normal;
        marker.onload = () => {
          let iconScale = parseFloat(this.gisMarkerData.gisMarkerWidth / marker.width).toFixed(1);
          return resolve(iconScale);
        };
      })
        .then((iconScale) => {
          if (this.gisMarkerData.gisMarkerImage.normal)
            this.gisMarkerIconNormal = this.createStyle(this.gisMarkerData.gisMarkerImage.normal, iconScale);

          if (this.gisMarkerData.gisMarkerImage.break) this.gisMarkerIconBreak = this.createStyle(this.gisMarkerData.gisMarkerImage.break, iconScale);

          if (this.gisMarkerData.gisMarkerImage.repair)
            this.gisMarkerIconRepair = this.createStyle(this.gisMarkerData.gisMarkerImage.repair, iconScale);

          if (this.gisMarkerData.gisMarkerImage.selected)
            this.gisMarkerIconSelected = this.createStyle(this.gisMarkerData.gisMarkerImage.selected, iconScale);
        })
        .then(() => {
          this.olPoint = new Point([this.geometry.lng, this.geometry.lat]);
          this.$marker = new Feature(this.olPoint);
          this.$marker.setId(this.$vnode.key);
          // this.$marker.setStyle(this.gisMarkerIconNormal)
          switch (this.status) {
            case 1:
              this.$marker.setStyle(this.gisMarkerIconNormal);
              break;
            case 2:
              this.$marker.setStyle(this.gisMarkerIconBreak);
              break;
            case 3:
              this.$marker.setStyle(this.gisMarkerIconRepair);
              break;
            default:
              this.$marker.setStyle(this.gisMarkerIconNormal);
              break;
          }

          let fcltLayerSource = this.$parent.getFcltLayerSource();
          fcltLayerSource.addFeature(this.$marker);
          this.$marker.set('vueObject', this);
        });
    } else {
      new Promise((resolve, reject) => {
        var marker = new Image();
        marker.crossOrigin = 'Anonymous';
        marker.src = 'static/images/gis/icon/River_CCTV_C.png';
        marker.onload = () => {
          let iconScale = parseFloat(28 / marker.width).toFixed(1);
          return resolve(iconScale);
        };
      })
        .then((iconScale) => {
          if (!this.isEventMarker) {
            this.gisMarkerIconNormal = this.createStyle('static/images/gis/icon/River_CCTV_C.png', iconScale);
            this.gisMarkerIconSelected = this.createStyle('static/images/gis/icon/River_CCTV_B.png', iconScale);
          } else {
            this.gisMarkerIconNormal = this.createStyle('static/images/gis/icon/incident-01.png', iconScale);
            this.gisMarkerIconSelected = this.createStyle('static/images/gis/icon/incident-select.png', iconScale);
          }
        })
        .then(() => {
          this.olPoint = new Point([this.geometry.lng, this.geometry.lat]);
          this.$marker = new Feature(this.olPoint);
          this.$marker.setId(this.$vnode.key);
          this.$marker.setStyle(this.gisMarkerIconNormal);
          let fcltLayerSource = this.$parent.getFcltLayerSource();
          fcltLayerSource.addFeature(this.$marker);
          let translate = null;
          if (this.draggable) {
            translate = new Translate({
              features: new Collection([this.$marker]),
            });
            this.$parent.getMapObject().addInteraction(translate);
            translate.on('translateend', function (evt) {
              let coord = me.$marker.getGeometry();
              me.$parent.$emit('markerDragEnd', {
                latLng: {
                  lat: coord.flatCoordinates[1],
                  lng: coord.flatCoordinates[0],
                },
                properties: me.properties,
              });
            });
          }
          this.$marker.set('vueObject', this);
        });
    }
  },
  mounted() {},
  destroyed() {
    let fcltLayerSource = this.$parent.getFcltLayerSource();
    fcltLayerSource.removeFeature(this.$marker);
    this.$marker = null;
  },
  watch: {
    geometry(newVal, oldVal) {
      if (this.olPoint) {
        this.olPoint.setCoordinates([newVal.lng, newVal.lat]);
      }
    },
    status(newVal, oldVal) {
      switch (newVal) {
        case 1:
          this.setIconNormal();
          break;
        case 2:
          this.setIconBreak();
          break;
        case 3:
          this.setIconRepair();
          break;
        default:
          this.setIconNormal();
          break;
      }
    },
    isOpenInfoWindow(newVal) {
      console.log(newVal);
    },
  },
  methods: {
    createStyle(src, scale) {
      return new Style({
        image: new IconSource(
          /** @type {module:ol/style/Icon~Options} */ ({
            anchor: [0.5, 0.96],
            crossOrigin: 'anonymous',
            src: src,
            scale: scale,
          })
        ),
        text: new Text({
          textAlign: 'center',
          textBaseline: 'top',
          font: '800 16px / 1 NanumGothicBold',
          text: this.properties.fcltName,
          fill: new Fill({ color: '#2f2f2f' }),
          stroke: new Stroke({ color: '#ffffff', width: 1 }),
          offsetX: 0,
          offsetY: 3,
        }),
      });
    },
    _imageEncode(arrayBuffer) {
      let u8 = new Uint8Array(arrayBuffer);
      let b64encoded = btoa(
        [].reduce.call(
          new Uint8Array(arrayBuffer),
          function (p, c) {
            return p + String.fromCharCode(c);
          },
          ''
        )
      );
      let mimetype = 'image/jpeg';
      return 'data:' + mimetype + ';base64,' + b64encoded;
    },
    setIconImageByStatus() {
      switch (this.status) {
        case 1:
          this.setIconNormal();
          break;
        case 2:
          this.setIconBreak();
          break;
        case 3:
          this.setIconRepair();
          break;
        default:
          this.setIconNormal();
          break;
      }
    },
    setIconNormal() {
      if (this.$marker && this.$marker.setStyle) this.$marker.setStyle(this.gisMarkerIconNormal);
    },
    setIconBreak() {
      if (this.$marker && this.$marker.setStyle) this.$marker.setStyle(this.gisMarkerIconBreak);
    },
    setIconRepair() {
      if (this.$marker && this.$marker.setStyle) this.$marker.setStyle(this.gisMarkerIconRepair);
    },
    setIconSelected() {
      if (this.$marker && this.$marker.setStyle) this.$marker.setStyle(this.gisMarkerIconSelected);
    },
  },
};
